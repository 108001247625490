import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { themeChange } from 'theme-change';
import 'tailwindcss/tailwind.css';
import 'daisyui/dist/full.css';
import { FiMenu } from 'react-icons/fi';
import logo from './logo.png';
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './Contact';
import SearchByAyah from './SearchByAyah';
import SearchByBook from './SearchByBook';

// Redirect component for the download route
const DownloadRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://onelink.to/6xzuc2';
  }, []);
  
  return null;
};

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    themeChange(false);
    const savedTheme = localStorage.getItem('theme') || 'light';
    document.documentElement.setAttribute('data-theme', savedTheme);

    const handleThemeChange = (event) => {
      const selectedTheme = event.target.value;
      document.documentElement.setAttribute('data-theme', selectedTheme);
      localStorage.setItem('theme', selectedTheme);
    };

    const themeSelector = document.querySelector('[data-choose-theme]');
    if (themeSelector) {
      themeSelector.value = savedTheme;
      themeSelector.addEventListener('change', handleThemeChange);
    }

    return () => {
      if (themeSelector) {
        themeSelector.removeEventListener('change', handleThemeChange);
      }
    };
  }, []);

  return (
    <Router>
      <div className="relative">
        <button 
          className="absolute top-4 right-4 text-3xl" 
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FiMenu />
        </button>
        
        {menuOpen && (
          <div className="absolute top-12 right-4 bg-white shadow-lg rounded-lg p-4 z-50">
            <ul className="space-y-2">
              <li>
                <Link to="/" onClick={() => setMenuOpen(false)}>
                  ابحث حسب الآية
                </Link>
              </li>
              <li>
                <Link to="/search-by-book" onClick={() => setMenuOpen(false)}>
                  ابحث حسب الكتاب
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" onClick={() => setMenuOpen(false)}>
                  سياسة الخصوصية
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setMenuOpen(false)}>
                  اتصل بنا
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <Routes>
        <Route path="/" element={<SearchByAyah />} />
        <Route path="/search-by-book" element={<SearchByBook />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/download" element={<DownloadRedirect />} />
      </Routes>
    </Router>
  );
}

export default App;